import { ActionContext } from 'vuex'
import { NotificationState } from './notification'
// import { api} from '@/services/api'

//molds
import { PainelIconItem } from '@/molds/ListPanelIconsModel'

export default {
	addNotification(store: ActionContext<NotificationState, string>, nitification: PainelIconItem) {
		const nitifi = nitification
		nitifi.id = store.getters.notifications.length+1 
		store.commit('addNotification', nitifi)		
	},

	removeNotification(store: ActionContext<NotificationState, string>, notifi: PainelIconItem): void {
		const notifications = store.getters.notifications
		for (let i = 0; i < notifications.length; i++) {
			if(notifications[i].id == notifi.id){
				notifications.splice(i, 1)
				break;
			}
		}
		store.commit('setNotification', notifications)
	},

	clearNotification(store: ActionContext<NotificationState, string>): void {
		store.commit('setNotification', [])
	}

}
	
