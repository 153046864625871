import { Process, ProcessState } from './process'
import { WolfApiData } from "@/components/wrappers/molds/TableOptions";

export default {
	process(state: ProcessState): Process[] {
		return state.process
	},
	wolfApiProcess(state: ProcessState): WolfApiData {
		return state.wolfApiProcess
	},
	loader(state: ProcessState): boolean {
		return state.loader
	},
	loaderList(state: ProcessState): boolean {
		return state.loaderList
	},
	error(state: ProcessState): any {
		return state.error
	}
}
	
