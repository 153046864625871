import { ActionContext } from 'vuex'
import { Group, GroupState } from './group'
import { api } from '@/services/api'

export default {
	// ============ fetch ===============
	async fetchGroups(store: ActionContext<GroupState, string>, paramQuery: string): Promise<void> {
		store.commit('setLoader', true)
		store.commit('setGroups', [])		
		store.commit('setError', false)	
		await api.get(`/group${paramQuery}`).then(
			response => {
				if(response.data.list){
					store.commit('setGroups', response.data)
				}else{
					store.commit('setError', 5000)
				}
			},
			error => {
				store.commit('setError', error)
			}	
		)
		store.commit('setLoader', false)
	},
	async fetchGroup(store: ActionContext<GroupState, any>, id: string): Promise<void> {	
		store.commit('setLoader', true)	
		store.commit('setError', false)
		store.commit('setGroup', {})
		await api.get(`/group/${id}`).then(
			response => {
				if(response.data){
					store.commit('setGroup', response.data)
				}
			},
			error => {
				store.commit('setError', error)
			}	
		)
		store.commit('setLoader', false)
	},
	async fetchMyGroups(store: ActionContext<GroupState, string>): Promise<void> {
		store.commit('setLoader', true)	
		store.commit('setError', false)	
		await api.get(`/group/mygroups`).then(
			response => {
				if(response.data.list){
					store.commit('setMyGroups', response.data.list)
				}else{
					store.commit('setError', 5000)
				}
			},
			error => {
				store.commit('setError', error)
			}	
		)
		store.commit('setLoader', false)
	},
	async fetchUsersGroup(store: ActionContext<GroupState, string>, idGroup: string): Promise<void> {
		store.commit('setLoader', true)	
		store.commit('setError', false)
		await api.get(`/group/${idGroup}/user`).then(
			response => {
				store.commit('setLoader', false)
				if(response.data.list){
					store.commit('setUsersGroup', response.data.list)
				}else{
					store.commit('setError', response.data)
				}
			},
			error => {
				store.commit('setLoader', false)
				store.commit('setError', error)
			}	
		)
	},
	// ============= PUT =============
	async updateGroup(store: ActionContext<GroupState, any>, data: any): Promise<void> {	
		store.commit('setLoader', true)
		store.commit('setError', false)	
		await api.put(`/group/${data.idGroup}`, data.group).then(
			response => {
				store.commit('setLoader', false)
			},
			error => {
				store.commit('setLoader', false)
				store.commit('setError', error)
			}
		)
	},
	// ========== POST ============
	async createGroup(store: ActionContext<GroupState, any>, group: Group): Promise<void> {	
		store.commit('setLoader', true)
		store.commit('setError', false)	
		await api.post(`/group`, group).then(
			response => {
				if(response.data){
					store.commit('setNewGroup', response.data)
				}
				store.commit('setLoader', false)
			},
			error => {
				store.commit('setLoader', false)
				store.commit('setError', error)
			}	
		)
	},
	async dispatchInviteGroup(store: ActionContext<GroupState, any>, data: any): Promise<void> {	
		store.commit('setError', false)
		await api.post(`/group/${data.idGroup}/user`, data.user).then(
			() => {
				store.commit('setError', false)
			},
			error => {
				store.commit('setError', error)
			}	
		)
	},
	async dispatchRequestEntry(store: ActionContext<GroupState, any>, data: any): Promise<void> {	
		store.commit('setError', false)
		await api.post(`/group/requestentry/${data.idGroup}`, data.user).then(
			() => {
				store.commit('setError', false)
			},
			error => {
				store.commit('setError', error)
			}	
		)
	},

}
	
