import { ActionContext } from 'vuex'
import { api } from '@/services/api'
import { UserState } from './user'
import { User } from '@/molds/User'

export default {
	//gets
	async fetchUsers(store: ActionContext<UserState, any>, paramQuery: string): Promise<void> {	
		store.commit('setUsers', {list:[]})
		store.commit('setLoader', true)	
		store.commit('setError', false)	
		await api.get(`/user${paramQuery}`).then(
			response => {
				store.commit('setLoader', false)
				if(response.data.list){
					store.commit('setUsers', response.data)
				}else{
					store.commit('setError', 5000)
				}
			},
			error => {
				store.commit('setLoader', false)
				store.commit('setError', error)
			}	
		)
	},
	async fetchUser(store: ActionContext<UserState, any>, id: string): Promise<void> {	
		store.commit('setLoader', true)	
		store.commit('setUser', {})	
		store.commit('setError', false)	
				
		await api.get(`/user/${id}`).then(
			response => {
				store.commit('setLoader', false)
				store.commit('setUser', response.data)				
			},
			error => {
				store.commit('setLoader', false)
				store.commit('setError', error)
			}	
		)
	},
	async updateUser(store: ActionContext<UserState, any>, data: any): Promise<void> {	
		store.commit('setLoader', true)
		store.commit('setError', false)	
		await api.put(`/user/${data.id}`, data.user).then(
			() => {
				store.commit('setLoader', false)
				store.commit('setError', false)
			},
			error => {
				store.commit('setLoader', false)
				store.commit('setError', error)
			}	
		)
	},
	async updateStatusUser(store: ActionContext<UserState, any>, data: any): Promise<void> {	
		store.commit('setError', false)
		await api.put(`/user/${data.id}/setstatus`, data.user).then(
			() => {
				store.commit('setError', false)
			},
			error => {
				store.commit('setError', error)
			}	
		)
	},
	async updatePasswdUser(store: ActionContext<UserState, any>, data: any): Promise<void> {	
		store.commit('setError', false)
		await api.put(`/user/${data.idUser}/setpassword`, data.send).then(
			() => {
				store.commit('setError', false)
			},
			error => {
				store.commit('setError', error)
			}	
		)
	},
	//post
	async createUser(store: ActionContext<UserState, any>, user: User): Promise<void> {	
		store.commit('setNewUser', {})
		store.commit('setError', false)
		store.commit('setLoader', true)
		await api.post('/user', user).then(
			response => {
				store.commit('setNewUser', response.data)
			},
			error => {
				store.commit('setError', error)
			}	
		)
		store.commit('setLoader', false)
	},
	//puts
	async updatePermissionUser(store: ActionContext<UserState, any>, data: any): Promise<void> {	
		store.commit('setError', false)
		store.commit('setLoader', true)
		await api.put(`/user/${data.idUser}/setpermission`, data.permission).then(
			() => {
				store.commit('setLoader', false)
			},
			error => {
				store.commit('setError', error)
			}	
		)
		store.commit('setLoader', false)
	}
}