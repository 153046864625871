import { ActionContext } from 'vuex'
import { AuthState, Login } from './auth'
import { AxiosError, AxiosResponse } from 'axios'
import { api, setToken, clearToken } from '@/services/api'

export default {
	async auth(store: ActionContext<AuthState, string>, login: Login): Promise<void> {
		store.commit('setError', false)
		store.commit('setLoader', true)
		await api.post(
			"/perfil/auth/", 
			login,{ 
				headers: {
					Authorization: 'apiKey 4KCedzRah4XvTJfenTH0jC:6NtGSyRH4S03bNRagzTCbA',
				}
			}).then((response: AxiosResponse) => {				
				if(response.data?.Authorization){
					setToken(response.data.Authorization)
				}else{
					store.commit('setError', 5000)
				}
			},(error: AxiosError) => {
				if(error.response?.status){
					store.commit('setError', error.response?.status)
				}else{
					store.commit('setError', error)
				}
			}
		)
		store.commit('setLoader', false)
	},

	logout(): void {
		clearToken()
	},
}