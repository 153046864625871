import { ActionContext } from 'vuex'
import { PerfilState } from './perfil'
import { api } from '@/services/api'
import { User } from '@/molds/User'

export default {
	async fetchPerfil(store: ActionContext<PerfilState, string>): Promise<void> {
		store.commit('setLoader', true)
		store.commit('setPerfil', {})
		store.commit('setError', false)
		await api.get('/perfil').then(
			(response) => {
				store.commit('setPerfil', response.data)
			},
			error => {
				store.commit('setError', error)
			}
		)
		store.commit('setLoader', false)
	},
	async updatePerfil(store: ActionContext<PerfilState, string>, perfil: User): Promise<void> {
		store.commit('setPerfil', {})
		store.commit('setLoader', true)
		store.commit('setError', false)
		await api.put('/perfil', perfil).then(
			async () => {
				await store.dispatch('fetchPerfil')
			},
			error => {
				store.commit('setError', error)
			}
		)
		store.commit('setLoader', false)
	},
	async updatePerfilPasswd(store: ActionContext<PerfilState, any>, data: any): Promise<void> {	
		store.commit('setError', false)
		await api.put('/perfil/passwd', data).then(
			() => {
				store.commit('setError', false)
			},
			error => {
				store.commit('setError', error)
			}	
		)
	},
}
	
