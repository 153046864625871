import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  // {
  //   path: '/',
	// 	name: 'public',
	// 	component:() => import('@/views/public/publicHome/PublicHome.vue'),
  // }, 
	// ################ Public ################                       
	{
		path: '/',
		name: 'login',
		component: () => import('@/views/login/Login.vue')
	},
	{
		path: '*',
		name: '404',
		component: () => import('@/views/public/page404/Page404.vue')
	},
	// ################ Private ################ 
	{
		path: '/home',
		name: 'home',
		component: () => import('@/views/private/home/Home.vue')
	},
	{
		path: '/perfil',
		name: 'perfil',
		component: () => import('@/views/private/perfil/Perfil.vue')
	},
	{
		path: '/search-process',
		name: 'searchProcess',
		component: () => import('@/views/private/searchProcess/SearchProcess.vue')
	},
	{
		path: '/details/:idGroup/:idProcess',
		name: 'details',
		component: () => import('@/views/private/details/Details.vue')
	},
	{
		path: '/details2/:idGroup/:idProcess',
		name: 'details',
		component: () => import('@/views/private/details2/Details2.vue')
	},
	{
		path: '/file',
		name: 'file',
		component: () => import('@/views/private/file/File.vue')
	},
	{
		path: '/search-profile-perm',
		name: 'searchProfilePerm',
		component: () => import('@/views/private/searchProfilePerm/SearchProfilePerm.vue')
	},
	{
		path: '/perm-profile/:id',
		name: 'permProfile',
		component: () => import('@/views/private/permProfile/PermProfile.vue')
	},
	{
		path: '/new-perfil-perm',
		name: 'newPerfilPerm',
		component: () => import('@/views/private/newPerfilPerm/NewPerfilPerm.vue')
	},
	{
		path: '/my-group/:id',
		name: 'myGroup',
		component: () => import('@/views/private/myGroup/MyGroup.vue')
	},
	{
		path: '/new-group',
		name: 'newGroup',
		component: () => import('@/views/private/newGroup/NewGroup.vue')
	},
	{
		path: '/new-process',
		name: 'newProcess',
		component: () => import('@/views/private/newProcess/NewProcess.vue')
	},
	{
		path: '/new-user',
		name: 'newUser',
		component: () => import('@/views/private/newUser/NewUser.vue')
	},
	{
		path: '/search-users',
		name: 'searchUsers',
		component: () => import('@/views/private/searchUsers/SearchUsers.vue')
	},
	{
		path: '/user/:id',
		name: 'user',
		component: () => import('@/views/private/user/User.vue')
	},
	{
		path: '/group/:id',
		name: 'gorup',
		component: () => import('@/views/private/group/Group.vue')
	},
	{
		path: '/group-admin',
		name: 'groupAdmin',
		component: () => import('@/views/private/groupAdmin/GroupAdmin.vue')
	},
	// {
	// 	path: '/admin',
	// 	name: 'admin',
	// 	component: () => import('@/views/test/Test.vue'),
	// 	children:[
	// 		{
	// 			path: '/admin/search-group',
	// 			name: 'searchGroups',
	// 			component: () => import('@/views/private/searchGroups/SearchGroups.vue')
	// 		},
	// 	]
	// },


	// {
	// 	path: '/test',
	// 	name: 'test',
	// 	component: () => import('@/views/test/Test.vue')
	// }
]

export default routes