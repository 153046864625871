import { User } from '@/molds/User'
import { Group, GroupState } from './group'

export default {
	group(state: GroupState): Group {
		return state.group
	},
	groups(state: GroupState): Group[] {
		return state.groups
	},
	usersGroup(state: GroupState): User[] {
		return state.usersGroup
	},
	myGroups(state: GroupState): Group[] {
		return state.myGroups
	},
	newGroup(state: GroupState): Group {
		return state.newGroup
	},
	loader(state: GroupState): boolean {
		return state.loader
	},
	error(state: GroupState): any {
		return state.error
	}
}
	
