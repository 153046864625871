import { UserState } from './user';
import { User } from '@/molds/User'

export default {
	user(state: UserState): User {
		return state.user;
  },
	users(state: UserState): User[] {
		return state.users;
  },
	newUser(state: UserState): User {
		return state.newUser;
  },
	loader(state: UserState): boolean {
		return state.loader;
	},
	error(state: UserState): any {
		return state.error;
	},
};