import { UserState } from './user'
import { User } from '@/molds/User'

export default {
	setUser(state: UserState, user: User): void {
		state.user = user
	},
	setNewUser(state: UserState, newUser: User): void {
		state.newUser = newUser
	},
	setUsers(state: UserState, users: User[]): void {
		state.users = users
	},
	setLoader(state: UserState, loader: boolean): void {
		state.loader = loader
	},
	setError(state: UserState, error: any): void {
		state.error = error
	},
}