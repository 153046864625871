import { ActionContext } from 'vuex'
import { PermissionState, PermissionProfile } from './permission'
import { api } from '@/services/api'

export default {
	//gets
	async fetchGroupUserPermission(store: ActionContext<PermissionState, any>, data: any): Promise<void> {
		store.commit('setLoader', true)	
		store.commit('setError', false)	
		await api.get(`/group/${data.idGroup}/user/${data.idUser}/permission`).then(
			response => {
				store.commit('setGroupUserPermission', response.data)
			},
			error => {
				store.commit('setError', error)
			}	
		)
		store.commit('setLoader', false)
	},
	async fetchPermissionProfiles(store: ActionContext<PermissionState, any>, paramQuery: string): Promise<void> {	
		
		store.commit('setPermissionProfiles', {list:[]})
		store.commit('setLoader', true)
		store.commit('setError', false)	

		await api.get(`/permission${paramQuery}`).then(
			response => {
				store.commit('setLoader', false)
				if(response.data.list){
					store.commit('setPermissionProfiles', response.data)
				}else{
					store.commit('setError', 5000)
				}
			},
			error => {				
				store.commit('setLoader', false)
				store.commit('setError', error)
			}	
		)
	},
	async fetchPermissionProfile(store: ActionContext<PermissionState, any>, idPermission: string): Promise<void> {	
		store.commit('setPermissionProfile', {})
		store.commit('setLoader', true)
		store.commit('setError', false)

		await api.get(`/permission/${idPermission}`).then(
			response => {
				store.commit('setPermissionProfile', response.data)
			},
			error => {				
				store.commit('setError', error)
			}	
		)
		store.commit('setLoader', false)
	},
	async fetchPermissionsInfo(store: ActionContext<PermissionState, any>): Promise<void> {	
		store.commit('setPermissionsInfo', [])
		store.commit('setLoader', true)	
		store.commit('setError', false)	
		
		await api.get(`/rulelist?_limit=100`).then(
			response => {
				// if(response.data.list){
					store.commit('setPermissionsInfo', response.data.list)
				// }else{
				// 	store.commit('setError', 5000)
				// }
			},
			error => {				
				store.commit('setError', error)
			}	
		)
		store.commit('setLoader', false)
	},

	//puts
	async updateGroupUserPermission(store: ActionContext<PermissionState, any>, data: any): Promise<void> {
		store.commit('setLoader', true)	
		store.commit('setError', false)	
		await api.put(`/group/${data.idGroup}/user/${data.idUser}/permission`, data.permission).then(
			() => {
				store.commit('setLoader', false)
			},
			error => {
				store.commit('setLoader', false)
				store.commit('setError', error)
			}	
		)
	},
	async updatePermissionProfile(store: ActionContext<PermissionState, any>, data: any): Promise<void> {
		store.commit('setLoader', true)	
		store.commit('setError', false)
		await api.put(`/permission/${data.idPermission}/permission`, data.permission).then(
			() => {
				store.commit('setLoader', false)
			},
			error => {
				store.commit('setLoader', false)
				store.commit('setError', error)
			}	
		)
	},
	async updateDataPermissionProfile(store: ActionContext<PermissionState, any>, data: any): Promise<void> {
		store.commit('setLoader', true)	
		store.commit('setError', false)
		await api.put(`/permission/${data.idPermission}`, data.permissionProfile).then(
			() => {
				store.commit('setLoader', false)
			},
			error => {
				store.commit('setLoader', false)
				store.commit('setError', error)
			}	
		)
	},

	//posts
	async createPermissionProfile(store: ActionContext<PermissionState, any>, perfilPerm: PermissionProfile): Promise<void> {
		store.commit('setLoader', true)	
		store.commit('setError', false)
		store.commit('setNewPermissionProfile', null)
		await api.post('permission', perfilPerm).then(
			response => {
				store.commit('setLoader', false)
				store.commit('setNewPermissionProfile', response.data)
			},
			error => {
				store.commit('setError', error)
			}	
		)
		store.commit('setLoader', false)
	},

	resetNewPrermissionProfile(store: ActionContext<PermissionState, any>): void {
		store.commit('setNewPermissionProfile', null)
	}
	
}
	
