export default {
	groupUserPermission: {},
	permissionProfiles: {},
	permissionProfile: {},
	newPermissionProfile: {},
	permissionsInfo: [],
	loader: false,
	error: {}
}
	
