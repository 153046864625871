import { User } from '@/molds/User'
import { Group, GroupState } from './group'

export default {
	setGroup(state: GroupState, group: Group): void {
		state.group = group
	},
	setGroups(state: GroupState, groups: Group[]): void {
		state.groups = groups
	},
	setUsersGroup(state: GroupState, users: User[]): void {
		state.usersGroup = users
	},
	setMyGroups(state: GroupState, myGroups: Group[]): void {
		state.myGroups = myGroups
	},
	setNewGroup(state: GroupState, newGroup: Group): void {
		state.newGroup = newGroup
	},
	setLoader(state: GroupState, loader: boolean): void {
		state.loader = loader
	},
	setError(state: GroupState, error: any): void {
		state.error = error
	},
}
	
