import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
//modules
import auth from './modules/auth'
import user from './modules/user'
import group from './modules/group'
import perfil from './modules/perfil'
import process from './modules/process'
import permission from './modules/permission'
import notification from './modules/notification'

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
});

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
		auth,
		user,
		group,
		perfil,
		process,
		permission,
		notification
	},
	plugins: [vuexLocalStorage.plugin],
})
