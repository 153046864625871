import { NotificationState } from './notification'
import { PainelIconItem } from "@/molds/ListPanelIconsModel";

export default {
	addNotification(state: NotificationState, notification: PainelIconItem): void {
		state.notifications.push(notification)		
	},
	setNotification(state: NotificationState, notifications: PainelIconItem[]): void {
		state.notifications = notifications		
	},
}
	
