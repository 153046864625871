import Vue from 'vue'
import VueRouter from 'vue-router'
import { verifyToken } from '@/services/api'
import routes from './routes';

Vue.use(VueRouter)

const app = document.getElementById('app')

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
	scrollBehavior() {
		if(app != null){
			app.scrollIntoView()
		}
		
	}
})

router.beforeEach((to, from, next) => {
	//Proteção de página	
	if(verifyToken()){
		if(to.name == "login"){
			next('/home')
		} 
		next()
	}else{ //não há token
		//Se não for página "login" proteger
		if(to.name != "login"){
			next('/')
		}else{
			next()
		}
	}
})

export default router
