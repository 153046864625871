import { GroupUserPermission, PermissionState, PermissionProfile, PermissionInfo } from './permission'
import { WolfApiData } from '@/components/wrappers/molds/TableOptions';

export default {
	groupUserPermission(state: PermissionState): GroupUserPermission {
		return state.groupUserPermission
	},
	permissionProfiles(state: PermissionState): WolfApiData {
		return state.permissionProfiles
	},
	permissionProfile(state: PermissionState): PermissionProfile {
		return state.permissionProfile
	},
	newPermissionProfile(state: PermissionState): PermissionProfile {
		return state.newPermissionProfile
	},
	permissionsInfo(state: PermissionState): PermissionInfo[] {
		return state.permissionsInfo
	},
	loader(state: PermissionState): boolean {
		return state.loader
	},
	error(state: PermissionState): any {
		return state.error
	}
}
	
