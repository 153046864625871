import { ComponentVersion } from '@/components/version'
import { isBuffer } from 'lodash'

export const SYSTEM_PROD_MODE = true

export const VERSION = 'Versão 1.1.9'+ComponentVersion

export const PortalName = "Prefeitura Municipal de XXXX...."
export const PortalSite = "www.xxxxxx.es.gov.br"

export const API_BASE = {
	DEV:'http://espiritocidadao.impaire.com.br:5000',
	PROD:'http://apilaranjadaterra.espiritocidadao.org',
}

// 167.71.82.85 espiritocidadao.impaire.com.br

// 167.71.82.85 apiibiracu.espiritocidadao.org
// 167.71.82.85 gestoribiracu.espiritocidadao.org

// 167.71.82.85 apilaranjadaterra.espiritocidadao.org
// 167.71.82.85 gestorlaranjadaterra.espiritocidadao.org

// devgestorlaranjadaterra.espiritocidadao.org
// devgestoribiracu.espiritocidadao.org
// devapilaranjadaterra.espiritocidadao.org
// devapiibiracu.espiritocidadao.org

export const URL_BASE_LARANJA = {
	NAME: 'Prefeitura de Laranja da Terra',
	FRONT_HOST: {
		DEV: 'localhost:8080',
		PROD: 'gestorlaranjadaterra.espiritocidadao.org'
	},
	API_URL: {
		DEV: 'https://apilaranjadaterra.espiritocidadao.org',
		PROD: 'https://apilaranjadaterra.espiritocidadao.org'
	}
}

export const URL_BASE_IBIRACU = {
	NAME: 'Prefeitura de Ibiraçu',
	FRONT_HOST: {
		DEV: 'localhost:8081',
		PROD: 'gestoribiracu.espiritocidadao.org'
	},
	API_URL: {
		DEV: 'http://apiibiracu.espiritocidadao.org',
		PROD: 'https://apiibiracu.espiritocidadao.org'
	}
}

export const URL_BASE_TESTE = {
	NAME: 'Teste Local',
	FRONT_HOST: {
		DEV: 'localhost:8090',
		PROD: 'gestoribiracu.espiritocidadao.org'
	},
	API_URL: {
		DEV: 'http://apitransparencia2.laranjadaterra.es.gov.br:9010',
		PROD: 'https://apiibiracu.espiritocidadao.org'
	}
}

export const BASE_MAPS = [
	URL_BASE_LARANJA,
	URL_BASE_IBIRACU,
	// URL_BASE_TESTE
]

export function GetHostUrl(){
	const url = window.location;
	return url.host;
}

export function GetBase(){
	const host = GetHostUrl();
	const mode = SYSTEM_PROD_MODE? 'PROD' : 'DEV';
	const base = BASE_MAPS.find ( (item) => {
		return item.FRONT_HOST[mode] == host
	})
	return ( base != undefined )? base : false;
}

export function GetBaseName(){
	const base = GetBase();
	return ( base != false )? base.NAME : '';
}

// export function GetUrlBasePath(){
// 	const base = GetBase();
// 	if(!base){
// 		return '';
// 	}
// 	return SYSTEM_PROD_MODE? base.API_URL.PROD : base.API_URL.DEV;
// }

export function UrlBase(): string {
	const base = GetBase();
	if(!base){
		console.error("URL da API não encontrada")
		return '';
	}
	if(SYSTEM_PROD_MODE){
		return base.API_URL.PROD
	} 
	console.warn("[main] System in Devmode");
	return base.API_URL.DEV
}

export function Version(): string {
	if(SYSTEM_PROD_MODE){
		return VERSION
	} return `Devmode | ${VERSION}`
}

export function setConfigSidebar(value: any): void {
	return localStorage.setItem("configSidenav", `${value}`);
}

export function getConfigSidebar(): string | null {
	return localStorage.getItem("configSidenav");
}