import axios, { AxiosError, AxiosResponse } from "axios";
import { UrlBase } from '@/config'

export const api = axios.create({
	baseURL: UrlBase(),
	timeout: 30 * 1000, // maximo 1 min
	headers: {
		'Access-Control-Allow-Origin': '*',
    "Content-type": "application/json"
  }
});

export const apiFormData = axios.create({
	baseURL: UrlBase(),
	timeout: 30 * 1000, // maximo 1 min
	headers: {
		'Access-Control-Allow-Origin': '*',
    "Content-type": "multipart/form-data"
  }
});

// api.interceptors.request.use(function (config) {
//   console.log(config);
  
//   return config;
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error);
// });



api.interceptors.response.use(
  response => {
    return response;
  },
  (error: AxiosError) => {
		//if(error.response?.status){
		return Promise.reject(error);
			//}
			//return Promise.reject({status: 0})
  })

export function setToken(jwt: string): void {
	localStorage.setItem('user-token', jwt)
  api.defaults.headers.common["Accept-Language"] = 'pt-br';
  api.defaults.headers.common["Authorization"] = `bearer ${jwt}`;
  apiFormData.defaults.headers.common["Accept-Language"] = 'pt-br';
  apiFormData.defaults.headers.common["Authorization"] = `bearer ${jwt}`;
}

export function getToken(): string | null{
	return localStorage.getItem('user-token')
}

export function verifyToken(): boolean {
	const token = getToken()
	if( token == null || token == '' || token == 'null' || token == undefined ){
		return false
	}
	setToken(token)
	return true
}

export function clearToken() {
	localStorage.removeItem('user-token')
  delete api.defaults.headers.common["Authorization"];
  delete apiFormData.defaults.headers.common["Authorization"];
}

export default api;