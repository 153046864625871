import { GroupUserPermission, PermissionState, PermissionProfile, PermissionInfo } from './permission'
import { WolfApiData } from '@/components/wrappers/molds/TableOptions';

export default {
	setGroupUserPermission(state: PermissionState, groupUserPermission: GroupUserPermission): void {
		state.groupUserPermission = groupUserPermission
	},
	setPermissionProfiles(state: PermissionState, permissionProfiles: WolfApiData): void {
		state.permissionProfiles = permissionProfiles
	},
	setNewPermissionProfile(state: PermissionState, newPermissionProfile: PermissionProfile): void {
		state.newPermissionProfile = newPermissionProfile
	},
	setPermissionProfile(state: PermissionState, permissionProfile: PermissionProfile): void {
		state.permissionProfile = permissionProfile
	},
	setPermissionsInfo(state: PermissionState, permissionsInfo: PermissionInfo[]): void {
		state.permissionsInfo = permissionsInfo
	},
	setLoader(state: PermissionState, loader: boolean): void {
		state.loader = loader
	},
	setError(state: PermissionState, error: any): void {
		state.error = error
	},
}
	
