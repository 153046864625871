export default {
	group: null,
	groups: [],
	usersGroup: [],
	myGroups: [],
	newGroup: null,
	loader: false,
	error: false
}
	
