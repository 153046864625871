import { PerfilState } from './perfil'
import { User } from "@/molds/User";

export default {
	setPerfil(state: PerfilState, perfil: User): void {
		state.perfil = perfil
	},
	setLoader(state: PerfilState, loader: boolean): void {
		state.loader = loader
	},
	setError(state: PerfilState, error: any): void {
		state.error = error
	},
}
	
