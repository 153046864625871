import { PerfilState } from './perfil'
import { User } from "@/molds/User";

export default {
	perfil(state: PerfilState): User {
		return state.perfil
	},
	loader(state: PerfilState): boolean {
		return state.loader
	},
	error(state: PerfilState): any {
		return state.error
	}
}
	
